import React, { useEffect, useState } from 'react';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { format } from 'date-fns';
import { Field, Form, Formik } from 'formik';
import InputMask from 'react-input-mask';
import UIContainer from '../../UI/Container/UIContainer';
import FormInputDate from '../../UI/Form/InputDate/FormInputDate';
import UIFormTitle from '../../UI/Form/Title/UIFormTitle';
import validationSchema from './validationSchema';
import UIFormContainerSave from '../../UI/Form/ContainerSave/UIFormContainer';
import useApi from '../../../utils/useApi';
import useStorage from '../../../utils/useStorage';
import UIModalOldForm from '../../UI/ModalOldForm/UIModalOldForm';
import { isEqualObject } from '../../../utils/utils';

const initialState = {
  name: '',
  lastName: '',
  cpf: '',
  rg: '',
  bornIn: null,
  email: 'cliente@email.com',
  gender: 3,
  marialStatus: 1,
  children: 0,
  whatsapp: 389,
  city: 'Claro dos Poções',
  neighborhood: '',
  street: '',
  houseNumber: '',
};

const ClientNew = () => {
  const [open, setOpen] = useState(false);
  const [oldValues, setOldValues] = useStorage('formClient');
  const [registerLoad, registerInfo, setRegisterInfo] = useApi({
    method: 'post',
    url: '/customers',
  });

  function changeModal() {
    setOpen(!open);
  }

  function setFormValues() {
    setOldValues(null);
    changeModal();
  }

  useEffect(() => {
    if (oldValues && !isEqualObject(oldValues, initialState)) {
      changeModal();
    }
  }, []);

  function onSubmit(values) {
    const valuesClient = {
      ...values,
      bornIn: values.bornIn && format(new Date(values.bornIn), 'yyyy-MM-dd'),
    };
    registerLoad({
      data: valuesClient,
    });
  }

  return (
    <>
      <Formik
        initialValues={oldValues || initialState}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ setFieldValue, values, errors, touched }) => (
          <Form onBlur={() => setOldValues(values)}>
            <UIContainer container direction="row">
              <Grid item xs={12}>
                <UIFormTitle title="Novo cliente" />
              </Grid>
              <Grid item xs={12} md={2}>
                <Field
                  name="name"
                  label="Nome"
                  error={Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                  placeholder="Informe o nome do cliente"
                  fullWidth
                  as={TextField}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Field
                  name="lastName"
                  label="Sobrenome"
                  error={Boolean(errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                  placeholder="Informe o sobrenome do cliente"
                  fullWidth
                  as={TextField}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Field
                  name="cpf"
                  label="CPF"
                  error={Boolean(errors.cpf)}
                  helperText={touched.cpf && errors.cpf}
                  placeholder="Informe o CPF do cliente"
                  as={TextField}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Field
                  name="rg"
                  label="RG"
                  error={Boolean(errors.cpf)}
                  helperText={touched.cpf && errors.cpf}
                  placeholder="Informe o RG do cliente"
                  as={TextField}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputMask
                  value={values.whatsapp}
                  mask="(99) 9 9999-9999"
                  onChange={(ev) =>
                    setFieldValue(
                      'whatsapp',
                      ev.target.value.replace(/[^0-9]/g, ''),
                    )
                  }
                >
                  {() => (
                    <TextField
                      label="Whatsapp"
                      error={Boolean(errors.whatsapp)}
                      helperText={touched.whatsapp && errors.whatsapp}
                      placeholder="Informe o numero do whatsapp"
                    />
                  )}
                </InputMask>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormInputDate
                  value={values.bornIn}
                  clearable
                  label="Data de nascimento"
                  error={Boolean(errors.bornIn)}
                  helperText={touched.bornIn && errors.bornIn}
                  placeholder="Informe a data nascimento do cliente"
                  onChange={(value) => setFieldValue('bornIn', value)}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <FormControl fullWidth>
                  <InputLabel shrink id="client-new-gender">
                    Gênero
                  </InputLabel>
                  <Field
                    name="gender"
                    error={Boolean(errors.gender)}
                    helperText={touched.gender && errors.gender}
                    labelId="client-new-gender"
                    displayEmpty
                    fullWidth
                    as={Select}
                  >
                    <MenuItem disabled value="">
                      <em>Selecione o gênero</em>
                    </MenuItem>
                    <MenuItem value={1}>Masculino</MenuItem>
                    <MenuItem value={2}>Feminino</MenuItem>
                    <MenuItem value={3}>Indefinido</MenuItem>
                  </Field>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={1}>
                <Field
                  name="children"
                  error={Boolean(errors.children)}
                  helperText={touched.children && errors.children}
                  type="number"
                  label="Filhos"
                  placeholder="Numero de filhos"
                  fullWidth
                  as={TextField}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <InputLabel shrink id="client-new-marialStutus">
                    Estado civil
                  </InputLabel>
                  <Field
                    name="marialStatus"
                    error={Boolean(errors.marialStatus)}
                    helperText={touched.marialStatus && errors.marialStatus}
                    labelId="client-new-marialStutus"
                    displayEmpty
                    fullWidth
                    as={Select}
                  >
                    <MenuItem disabled value="">
                      <em>Selecione estado civil</em>
                    </MenuItem>
                    <MenuItem value={1}>Solteiro</MenuItem>
                    <MenuItem value={2}>Casado</MenuItem>
                    <MenuItem value={3}>Divorciado</MenuItem>
                  </Field>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <Field
                  name="email"
                  label="E-mail"
                  error={Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  type="email"
                  placeholder="Informe o e-mail do cliente"
                  fullWidth
                  as={TextField}
                />
              </Grid>
            </UIContainer>
            <UIContainer container direction="row">
              <Grid item xs={12}>
                <UIFormTitle title="Endereço" />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  name="street"
                  label="Rua / Avenida"
                  error={Boolean(errors.street)}
                  helperText={touched.street && errors.street}
                  placeholder="Informe o nome da rua"
                  fullWidth
                  as={TextField}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  name="neighborhood"
                  label="Bairro"
                  error={Boolean(errors.neighborhood)}
                  helperText={touched.neighborhood && errors.neighborhood}
                  placeholder="Informe o nome da bairro"
                  fullWidth
                  as={TextField}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  name="city"
                  label="Cidade"
                  error={Boolean(errors.city)}
                  helperText={touched.city && errors.city}
                  placeholder="Informe o nome da cidade"
                  fullWidth
                  as={TextField}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  name="houseNumber"
                  label="Número"
                  error={Boolean(errors.houseNumber)}
                  helperText={touched.houseNumber && errors.houseNumber}
                  placeholder="Informe o numero da casa"
                  fullWidth
                  as={TextField}
                />
              </Grid>
            </UIContainer>
            <UIFormContainerSave
              container
              justify="center"
              direction="row"
              messageSucess="Cliente cadastrado com sucesso"
              messageError="Alguma coisa está errada, verifique os campos"
              error={registerInfo.error}
              loading={registerInfo.loading || ''}
              data={registerInfo.data}
              setrequestinfo={setRegisterInfo}
              setFormValues={() => setOldValues(null)}
            />
            <UIModalOldForm
              open={open}
              changeModal={changeModal}
              setFormValues={setFormValues}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ClientNew;
