import React from 'react';
import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import './UIFormContainerSale.css';
import { formattedCurrency } from '../../../../utils/utils';

const UIFormContainerSale = ({ ...children }) => (
  <Grid className="UIFormContainerSave" container id={children.id}>
    <Grid
      container
      item
      direction="column"
      alignItems={children.mobile ? 'flex-end' : 'center'}
      justify="center"
      xs={12}
      md={4}
    >
      <Typography className="form-container-sale__subtotal">TOTAL: </Typography>
      <span className="form-container-sale__subtotal">{formattedCurrency(children.data?.total || 0)}</span>
      <Typography>DESCONTOS: </Typography>
      <span>{formattedCurrency(children.data?.discount || 0)}</span>
    </Grid>
    {/* <Grid
      container
      item
      justify="flex-end"
      alignItems="center"
      xs={12}
      md={6}
      className="form-container-sale__subtotal"
    >
      SUBTOTAL:
      {formattedCurrency(children.data.total || 0)}
    </Grid> */}
    <Grid container item justify="flex-end" alignItems="center" xs={12} md={8}>
      <Button
        variant="contained"
        className="UIFormContainerSale__buttons-saved"
        type="submit"
        onClick={children.onConfirmed}
        title="Atalho: Shift + Enter"
      >
        {children.sale.loading ? (
          <CircularProgress size="1.5rem" color="inherit" />
        ) : (
          'Fechar venda'
        )}
      </Button>
    </Grid>
  </Grid>
);

export default UIFormContainerSale;
