import React, { useContext } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import useStorage from '../../../utils/useStorage';
import StoreContext from '../../Store/context';
import useApi from '../../../utils/useApi';

const ErrorNoAutentication = () => {
  const { credencials, setCredencials } = useContext(StoreContext);
  const { openModal, setOpenModal } = useContext(StoreContext);
  const [, , remove] = useStorage('credencials');
  const userAcess = {};
  const history = useHistory();

  const [signIn, signInInfo] = useApi({
    method: 'get',
    onCompleted: (response) => {
      if (response) {
        userAcess.id = response.data.id;
        userAcess.name = response.data.name;
        userAcess.lastName = response.data.lastName;
        userAcess.username = response.data.username;
        userAcess.notifications = response.data.notifications;
        userAcess.profile = response.data.profile;
        userAcess.sessionToken = response.data.sessionToken;
        setCredencials(userAcess);
        setOpenModal(false);
        document.location.reload();
      }
    },
  });

  const initialState = {
    username: credencials.username,
    password: '',
    company: credencials.company,
  };

  function onSubmit(values) {
    const valuesUser = {
      username: values.username,
      password: values.password,
      company: values.company,
    };

    signIn({
      params: valuesUser,
      url: '/auth/user',
    });

    userAcess.company = values.company;
  }

  const removerUser = () => {
    remove();
    setCredencials(null);
    setOpenModal(false);
    history.push('/');
  };

  return (
    <Dialog
      open={openModal}
      id="error-no-autentication"
      onClose={() => setOpenModal(false)}
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Formik initialValues={initialState} onSubmit={onSubmit}>
        {() => (
          <Form>
            <DialogTitle id="alert-dialog-title">Sessão expirada</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                A sua sessão foi expirada. Por favor, faça o login novamente
                para voltar a fazer suas atividades!
              </DialogContentText>
              <Field
                label="Senha"
                name="password"
                type="password"
                placeholder="Insira sua senha"
                margin="dense"
                fullWidth
                as={TextField}
              />
              {signInInfo.error && (
                <DialogContentText id="alert-dialog-description">
                  <Typography color="error">
                    Sem sucesso, verifique sua senha e tente novamente
                  </Typography>
                </DialogContentText>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => removerUser()} color="primary" autoFocus>
                Sair
              </Button>
              <Button
                type="submit"
                color="primary"
                autoFocus
                startIcon={
                  signInInfo.loading && (
                    <CircularProgress size="0.9rem" color="inherit" />
                  )
                }
              >
                {signInInfo.loading ? 'Entrando' : 'Entrar novamente'}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default ErrorNoAutentication;
