import React from 'react';
import './UILogo.css';

const UILogo = ({ ...children }) => (
  <h3 className="UI-logo__duzz-commercial" style={{ fontSize: children.size }}>
    <span>Duzz</span>
    Commercial
  </h3>
);

export default UILogo;
