import React, { useContext, useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import {
  AppBar,
  Button,
  Fab,
  Grid,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import './navBar.css';

import CashierSelect from '../../Cashier/Select/cashierSelect';
import StoreContext from '../../Store/context';
import useApi from '../../../utils/useApi';
import UIModalCashierOpen from '../../UI/Modal/UIModalCashierOpen';

const MainNavBar = () => {
  const { credencials, modalCashierSelect, setModalCashierSelect } =
    useContext(StoreContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();

  const [cashierLoad, cashierInfo] = useApi({
    method: 'get',
    url: '/cash-registers',
  });

  const handleModalSelectCashier = () => {
    setModalCashierSelect(!modalCashierSelect);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const linkToPage = (route) => {
    if (history.location.pathname !== route) {
      history.push(route);
      handleClose();
    }
  };

  useEffect(() => {
    if (credencials.cashSession)
      cashierLoad({ params: { id: credencials.cashSession } });
  }, [credencials]);

  return (
    <>
      <input type="checkbox" id="main-base__slide-itens" />

      <AppBar position="fixed" className="main-base-navbar__appbar">
        <Toolbar className="main-base-navbar__content">
          <Grid className="main-base-navbar__pagination">
            <Link to="/home">Página inicial</Link>
          </Grid>
          <Grid className="main-base-navbar__buttons">
            <Button
              className="main-base-navbar__buttons-shortcuts"
              variant="contained"
              onClick={() => linkToPage('/home/vendas/nova')}
            >
              Nova venda
            </Button>
            <Button
              className="main-base-navbar__buttons-shortcuts"
              variant="contained"
              onClick={() => linkToPage('/home/vendas/comanda')}
            >
              Comandas
            </Button>
            <Button
              className="main-base-navbar__buttons-shortcuts"
              variant="contained"
              onClick={() => linkToPage('/home/clientes/novo')}
            >
              Novo Cliente
            </Button>
            <Button
              className="main-base-navbar__buttons-shortcuts"
              variant="contained"
              onClick={() => linkToPage('/home/caixas/detalhes')}
            >
              Abrir/ Fechar Caixa
            </Button>
            <Button
              className="main-base-navbar__button-select-cash"
              variant="outlined"
              onClick={handleModalSelectCashier}
            >
              {credencials.cashSession && cashierInfo.data
                ? cashierInfo.data && cashierInfo.data[0].name
                : 'Selecionar caixa'}
            </Button>
          </Grid>
        </Toolbar>

        <Tooltip className="main-base-navbar__icon-float" title="Mais opções">
          <Fab
            style={{ backgroundColor: '#17497F', color: '#fff' }}
            size="small"
            onClick={handleClick}
          >
            <AddIcon />
          </Fab>
        </Tooltip>

        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => linkToPage('/home/vendas/nova')}>
            Nova venda
          </MenuItem>
          <MenuItem onClick={() => linkToPage('/home/vendas/comanda')}>
            Comandas
          </MenuItem>
          <MenuItem onClick={() => linkToPage('/home/clientes/novo')}>
            Novo Cliente
          </MenuItem>
          <MenuItem onClick={() => linkToPage('/home/caixas/detalhes')}>
            Abrir/ Fechar Caixa
          </MenuItem>
        </Menu>
      </AppBar>
      <CashierSelect
        open={modalCashierSelect}
        handleModal={handleModalSelectCashier}
      />
      <UIModalCashierOpen />
    </>
  );
};

export default MainNavBar;
