import React, { useState } from 'react';
import {
  Collapse,
  Grid,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import './slideitens.css';
import UIModalExit from '../../../UI/ModalExit/UIModalExit';

const MainSlideItens = ({ ...children }) => {
  const [expanded, setExpanded] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  function toggleExpandedCollapse() {
    setExpanded(!expanded);
  }

  function toggleDialog() {
    setOpenDialog(!openDialog);
  }

  return (
    <>
      <Grid className="main-base-slide-item">
        <Grid
          container
          direction="row"
          alignItems="center"
          justify={children.open ? 'flex-start' : 'center'}
          className="main-base-slide-item__item"
          onClick={children.exit ? toggleDialog : toggleExpandedCollapse}
        >
          {children.icon}

          {children.options && (
            <Hidden xsUp={children.open}>
              <List
                component="div"
                disablePadding
                className="main-base-slide-item__submenu"
              >
                <span>{children.name}</span>
                {children.options.map((option) => (
                  <ListItem
                    onClick={option.action}
                    key={children.options.indexOf(option)}
                    button
                    style={{ padding: '5px 0px' }}
                  >
                    <ListItemText primary={option.name} />
                  </ListItem>
                ))}
              </List>
            </Hidden>
          )}

          <Hidden xsUp={!children.open}>
            <Grid
              container
              direction="column"
              className="main-base-slide-item__operador"
            >
              <span>{children.name}</span>
            </Grid>
          </Hidden>

          {children.open && <Grid item xs />}

          {/* segundo icone */}
          {children.open && !children.exit && (
            <IconButton onClick={toggleExpandedCollapse}>
              {expanded ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          )}
        </Grid>
        {children.open && children.options && (
          <Collapse
            in={expanded}
            timeout="auto"
            unmountOnExit
            className="main-base-slide-item__collapse"
          >
            <List component="div" disablePadding>
              {children.options.map((option) => (
                <ListItem
                  key={children.options.indexOf(option)}
                  onClick={option.action}
                  button
                >
                  <ListItemText primary={option.name} />
                </ListItem>
              ))}
            </List>
          </Collapse>
        )}

        <UIModalExit
          open={openDialog}
          toggleDialog={toggleDialog}
          exit={children.exit}
        />
      </Grid>
    </>
  );
};

export default MainSlideItens;
