import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  Button,
  CircularProgress,
  Dialog,
  InputLabel,
  Select,
  DialogActions,
  DialogContent,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  FormControlLabel,
  Typography,
  FormControl,
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import SearchIcon from '@material-ui/icons/Search';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CallMadeIcon from '@material-ui/icons/CallMade';
import CloseIcon from '@material-ui/icons/Close';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { useTheme } from '@material-ui/core/styles';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';

import { isValid, format } from 'date-fns';
import useApi from '../../../utils/useApi';
import StoreContext from '../../Store/context';

import UIContainer from '../../UI/Container/UIContainer';
import UIFormTitle from '../../UI/Form/Title/UIFormTitle';
import {
  emptyObject,
  formattedCurrency,
  formattedDateTimeIso,
  goToReferenceId,
  isEqualObject,
} from '../../../utils/utils';
import UIDialogTitle from '../../UI/Dialog/Title/UIDialogTitle';
import UIButtonSearch from '../../UI/Button/Search/Search';
import UIDialogConfirm from '../../UI/Dialog/Confirm/UIDialogConfirm';
import UIFilter from '../../UI/Button/Filter/UIFilter';
import GreenSwitch from '../../UI/Form/Switch/GreenSwitch';
import UIModalFilter from '../../UI/ModalFilter/UIModalFilter';
import FormInputDate from '../../UI/Form/InputDate/FormInputDate';
import UITablePagination from '../../UI/Table/Pagination/UITablePagination';
import useStyles from '../../../utils/useStyle';

const initialState = {
  id: '',
  description: '',
  name: '',
  value: null,
  valuePaid: null,
  createdAt: null,
  dueDate: null,
};

const initialTextConfirm = {
  closeAcount: '',
  confirmPayment: '',
};

const initialPayment = {
  valuePaid: 0,
  paymentMethod: 6,
};

const initialClick = {
  mouseX: null,
  mouseY: null,
};

const BillsToPay = (children) => {
  const { referenceId } = children;
  const { credencials, setModalCashierSelect } = useContext(StoreContext);
  const { disabledTextField } = useStyles();

  // verificar se é mobile
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [filter, setFilter] = useState(false);

  // modais de pesquisa e filtragem
  const [view, setView] = useState(false);
  const [modalValues, setModalValues] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // constantes para manipular modal e valores de finalizar conta
  const [modalTerminates, setModalTerminates] = useState(false);
  const [modalConfirmPayment, setModalConfirmPayment] = useState(false);
  const [textConfirm, setTextConfirm] = useState(initialTextConfirm);

  // paid para pesquisa
  const [paid, setPaid] = useState(false);

  // constantes para modal de pagamentos
  const [modalPay, setModalPay] = useState(false);
  const [payment, setPayment] = useState(initialPayment);
  const [alert, setAlert] = useState(false);
  const [alertUpdate, setAlertUpdate] = useState(false);
  const [alertTerminates, setAlertTerminates] = useState(false);

  const [search, setSearch] = useState({ paid: '0' });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // ação para click direito e esquerdo
  const [clickRight, setClickRight] = useState(initialClick);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [filterLoad, filterInfo, setFilterInfo] = useApi({
    debounceDelay: 500,
    method: 'get',
    url: '/bills-to-pay',
    params: { paid: '0' },
    onCompleted: (res) => {
      if (!res) {
        setFilterInfo();
      }
    },
  });

  const [updateLoad, updateInfo, setUpdateInfo] = useApi({
    method: 'put',
    url: '/bills-to-pay',
    params: { paid: '0' },
  });

  const [suppliersLoad, suppliersInfo] = useApi({
    method: 'get',
    url: '/suppliers',
  });

  const [paymentLoad, paymentInfo] = useApi({
    method: 'get',
    url: '/payments/methods',
  });

  const [payLoad, payInfo, setPayInfo] = useApi({
    method: 'patch',
    url: '/bills-to-pay',
  });

  const [terminatesLoad, terminatesInfo, setTerminatesInfo] = useApi({
    method: 'delete',
    url: '/bills-to-pay',
  });

  useEffect(() => {
    const params = referenceId
      ? { id: referenceId }
      : { paid: '0', id: referenceId };

    filterLoad({ params });
    suppliersLoad();
    paymentLoad();
    if (referenceId) {
      initialState.id = referenceId;
    }
  }, [referenceId]);

  function handleModalFilter() {
    setFilter(!filter);
  }

  function humanAttributes(values) {
    const supplier = suppliersInfo.data.find(
      (value) => value.id === values.supplier,
    );
    setModalValues({
      ...values,
      supplier: supplier.name,
      dueDate: formattedDateTimeIso(values.dueDate),
    });
  }

  const handleClickRight = (event, values) => {
    event.preventDefault();
    humanAttributes(values, true);
    setClickRight({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };

  const handleCloseClickRight = () => {
    setClickRight(initialClick);
  };

  const handleClickLeft = (event, values) => {
    humanAttributes(values, true);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseClickLeft = () => {
    setAnchorEl(null);
  };

  function handleModalPay(values, isClick) {
    if (values) {
      humanAttributes(values, true);
      const limitPay = Number(values.value) - Number(values.valuePaid);
      setPayment({
        ...payment,
        valuePaid: limitPay,
        limitPay,
      });
    }
    if (isClick) {
      const closeMenu = mobile ? handleCloseClickLeft : handleCloseClickRight;
      closeMenu();
    }
    setModalPay(!modalPay);
  }

  function changeValuesPayment(value, attribute) {
    if (Number(value) > 0) {
      setPayment({ ...payment, [attribute]: value, errors: false });
    } else {
      setPayment({ ...payment, errors: 'Pagamento invalido' });
    }
  }

  function handleModalTerminates(values, isClick) {
    if (values) {
      humanAttributes(values);
    }
    if (isClick) {
      const closeMenu = mobile ? handleCloseClickLeft : handleCloseClickRight;
      closeMenu();
    }
    setModalTerminates(!modalTerminates);
  }

  function handleModalUpdate(values, isClick) {
    if (values) {
      humanAttributes(values);
    }
    if (isClick) {
      const closeMenu = mobile ? handleCloseClickLeft : handleCloseClickRight;
      closeMenu();
    }
    setView(!view);
  }

  function onSearch(values) {
    const searchValues = {
      ...values,
      paid: !paid && '0',
      createdAt:
        isValid(values.createdAt) &&
        format(new Date(values.createdAt), 'yyyy-MM-dd'),
      dueDate:
        isValid(values.dueDate) &&
        format(new Date(values.dueDate), 'yyyy-MM-dd'),
    };
    filterLoad({
      debounced: true,
      params: emptyObject(searchValues),
      onCompleted: () => {
        setSearch(emptyObject(searchValues));
      },
    });
  }

  function onPaid(value) {
    const searchValues = {
      ...search,
      paid: !value && '0',
    };
    filterLoad({
      params: emptyObject(searchValues),
      onCompleted: () => {
        setSearch(emptyObject(searchValues));
      },
    });
    setPaid(value);
  }

  function onPay() {
    if (!credencials.cashSession) {
      setModalCashierSelect(true);
    } else if (Number(payment.valuePaid) > 0) {
      const data = {
        id: modalValues.id,
        userId: credencials.id,
        cashRegister: credencials.cashSession, // valor chumbado
        valuePaid: payment.valuePaid,
        paymentMethod: payment.paymentMethod,
      };
      payLoad({
        data,
        onCompleted: (res) => {
          setAlert(true);
          if (res) {
            setModalConfirmPayment(false);
            filterLoad({
              params: search,
            });
          }
        },
      });
    }
  }

  function onUpdate(values) {
    const data = {
      id: values.id,
      dueDate:
        isValid(values.dueDate) &&
        format(new Date(values.dueDate), 'yyyy-MM-dd'),
    };
    updateLoad({
      data,
      onCompleted: (res) => {
        setAlertUpdate(!alertUpdate);
        if (res) {
          filterLoad({
            params: search,
          });
          setView(false);
        }
      },
    });
  }

  function onTerminates() {
    if (textConfirm.closeAcount === 'Encerrar conta') {
      const params = { id: modalValues.id };
      terminatesLoad({
        params,
        onCompleted: (res) => {
          setAlertUpdate(!alertUpdate);
          if (res) {
            filterLoad({
              params: search,
            });
            setAlertTerminates(true);
          }
        },
      });
    } else {
      setTextConfirm({
        ...textConfirm,
        error: 'Digite o texto corretamente para poder finalizar',
      });
    }
  }

  return (
    <>
      <Formik onSubmit={onSearch} initialValues={initialState}>
        {({ submitForm, setFieldValue, values }) => (
          <Form onChange={submitForm}>
            <UIContainer container direction="row">
              <Grid
                item
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                xs={12}
              >
                <UIFormTitle title="Filtrar contas à pagar" />
                <UIFilter onClick={handleModalFilter} />
              </Grid>
              <Grid item xs={2} md={1}>
                <Field
                  name="id"
                  label="ID"
                  placeholder="ID"
                  fullWidth
                  as={TextField}
                />
              </Grid>
              <Grid item xs={10} md={4}>
                <Field
                  name="name"
                  label="Nome"
                  placeholder="Nome da conta"
                  fullWidth
                  as={TextField}
                />
              </Grid>
              <Hidden smDown>
                <Grid item xs={6} md={3}>
                  <FormInputDate
                    name="dueDate"
                    value={values.createdAt}
                    onChange={(value) =>
                      setFieldValue('createdAt', value, false)
                    }
                    clearable
                    label="Data de criação"
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <FormInputDate
                    name="dueDate"
                    value={values.dueDate}
                    onChange={(value) => setFieldValue('dueDate', value, false)}
                    minDate={values.createdAt}
                    clearable
                    label="Data vencimento"
                  />
                </Grid>
                <UIButtonSearch xs={1} />
              </Hidden>
            </UIContainer>

            <UIModalFilter
              open={filter}
              changeModal={handleModalFilter}
              submit={submitForm}
            >
              <Grid item xs={12}>
                <Field
                  name="description"
                  label="Descrição"
                  placeholder="Descrição da conta"
                  fullWidth
                  as={TextField}
                />
              </Grid>
              <Hidden mdUp>
                <Grid item xs={6} md={3}>
                  <FormInputDate
                    name="dueDate"
                    dateFormat="MM-DD-YYYY"
                    value={values.createdAt}
                    onChange={(value) =>
                      setFieldValue('createdAt', value, false)
                    }
                    clearable
                    label="Data de vencimento"
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <FormInputDate
                    name="dueDate"
                    dateFormat="MM-DD-YYYY"
                    value={values.dueDate}
                    onChange={(value) => setFieldValue('dueDate', value, false)}
                    minDate={values.createdAt}
                    clearable
                    label="Data vencimento"
                  />
                </Grid>
              </Hidden>
              <Grid item xs={6}>
                <CurrencyTextField
                  type="tel"
                  value={values.value}
                  label="Valor da conta"
                  variant="standard"
                  decimalCharacter=","
                  digitGroupSeparator="."
                  currencySymbol="R$"
                  outputFormat="string"
                  fullWidth
                  onChange={(ev, value) => setFieldValue('value', value)}
                />
              </Grid>
              <Grid item xs={6}>
                <CurrencyTextField
                  type="tel"
                  value={values.valuePaid}
                  label="Valor da pago"
                  variant="standard"
                  decimalCharacter=","
                  digitGroupSeparator="."
                  currencySymbol="R$"
                  outputFormat="string"
                  fullWidth
                  onChange={(ev, value) => setFieldValue('valuePaid', value)}
                />
              </Grid>
            </UIModalFilter>
          </Form>
        )}
      </Formik>
      <UIContainer>
        <Grid
          item
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          xs={12}
        >
          {filterInfo.loading && <CircularProgress />}
          {filterInfo.data && suppliersInfo.data && (
            <UIFormTitle
              title={`${filterInfo.data
                .reduce(
                  (a, b) => a + (parseFloat(b.value) - parseFloat(b.valuePaid)),
                  0,
                ).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} pendente`}
            />
          )}{' '}
          <FormControlLabel
            label={paid ? 'Esconder contas pagas' : 'Mostrar contas pagas'}
            labelPlacement="start"
            control={
              <GreenSwitch
                checked={paid}
                onChange={(ev) => onPaid(ev.target.checked)}
              />
            }
          />
        </Grid>
        <Grid item xs>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Nome</TableCell>
                  <TableCell>Fornecedor</TableCell>
                  <TableCell>Vencimento da conta</TableCell>
                  <TableCell>Valor</TableCell>
                  <TableCell>Valor pago</TableCell>
                  <TableCell>Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filterInfo.loading && <CircularProgress />}
                {filterInfo.data &&
                  suppliersInfo.data &&
                  filterInfo.data
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((data) => (
                      <TableRow
                        className="cashier-search__table-row"
                        style={{
                          cursor: 'context-menu',
                          backgroundColor:
                            (data.paid === '1' && '#EAEDED') ||
                            (data.paid === '0' && '#FFF'),
                        }}
                        tabIndex={-1}
                        onDoubleClick={() => handleModalUpdate(data)}
                        onContextMenu={
                          !mobile
                            ? (ev) => handleClickRight(ev, data)
                            : undefined
                        }
                        onClick={
                          mobile ? (ev) => handleClickLeft(ev, data) : undefined
                        }
                        key={data.id}
                      >
                        <TableCell component="th" scope="row">
                          {data.id}
                        </TableCell>
                        <TableCell>{data.name}</TableCell>
                        <TableCell>
                          {
                            suppliersInfo.data.find(
                              (value) => value.id === data.supplier,
                            ).name
                          }
                        </TableCell>
                        <TableCell>
                          {data.dueDate || 'Sem vencimento'}
                        </TableCell>
                        <TableCell>{formattedCurrency(data.value)}</TableCell>
                        <TableCell>
                          {formattedCurrency(data.valuePaid)}
                        </TableCell>
                        <TableCell>
                          <IconButton
                            disabled={data.paid === '1'}
                            title="Finalizar conta"
                            onClick={() => handleModalTerminates(data)}
                          >
                            <CloseIcon />
                          </IconButton>
                          <IconButton
                            disabled={data.paid === '1'}
                            title="Pagar conta"
                            onClick={() => handleModalPay(data)}
                          >
                            <MonetizationOnIcon />
                          </IconButton>
                          <IconButton
                            title="Visualizar"
                            onClick={() => handleModalUpdate(data)}
                          >
                            <SearchIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                <Menu
                  keepMounted
                  open={clickRight.mouseY !== null}
                  onClose={handleCloseClickRight}
                  anchorReference="anchorPosition"
                  anchorPosition={
                    clickRight.mouseY !== null && clickRight.mouseX !== null
                      ? {
                          top: clickRight.mouseY,
                          left: clickRight.mouseX,
                        }
                      : undefined
                  }
                >
                  <MenuItem
                    disabled={modalValues.paid === '1'}
                    onClick={() => handleModalPay(null, true)}
                  >
                    Pagar conta
                  </MenuItem>
                  <MenuItem onClick={() => handleModalTerminates(null, true)}>
                    Finalizar conta
                  </MenuItem>
                  <MenuItem onClick={() => handleModalUpdate(null, true)}>
                    Visualizar
                  </MenuItem>
                </Menu>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={() => handleCloseClickLeft()}
                >
                  <MenuItem
                    disabled={modalValues.paid === '1'}
                    onClick={() => handleModalPay(null, true)}
                  >
                    Pagar conta
                  </MenuItem>
                  <MenuItem onClick={() => handleModalTerminates(null, true)}>
                    Finalizar conta
                  </MenuItem>
                  <MenuItem onClick={() => handleModalUpdate(null, true)}>
                    Visualizar
                  </MenuItem>
                </Menu>
                {filterInfo.error && (
                  <TableRow>
                    <TableCell>Sem resultados encotrados</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <UITablePagination
              count={filterInfo.data ? filterInfo.data.length : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Grid>
      </UIContainer>
      {modalValues && (
        <Dialog open={view} onClose={() => handleModalUpdate()} fullWidth>
          <Formik
            enableReinitialize
            onSubmit={onUpdate}
            initialValues={modalValues}
          >
            {({ values, submitForm, setFieldValue }) => (
              <Form>
                <UIDialogTitle
                  id="form-dialog-title"
                  onClose={() => setView(false)}
                >
                  Conta à pagar #{modalValues.id}
                </UIDialogTitle>
                <DialogContent>
                  <Grid container direction="row" spacing={1}>
                    <Grid item xs={12}>
                      <TextField
                        value={modalValues.name}
                        label="Nome"
                        fullWidth
                        className={disabledTextField}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        value={modalValues.description}
                        label="Descrição"
                        fullWidth
                        className={disabledTextField}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CurrencyTextField
                        type="tel"
                        value={modalValues.value}
                        label="Valor da conta"
                        variant="standard"
                        decimalCharacter=","
                        digitGroupSeparator="."
                        currencySymbol="R$"
                        outputFormat="string"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CurrencyTextField
                        type="tel"
                        value={modalValues.valuePaid}
                        label="Valor pago"
                        variant="standard"
                        decimalCharacter=","
                        digitGroupSeparator="."
                        currencySymbol="R$"
                        outputFormat="string"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        value={modalValues.supplier}
                        label="Fornecedor"
                        fullWidth
                        className={disabledTextField}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        value={modalValues.referenceId}
                        label="ID da compra"
                        fullWidth
                        className={disabledTextField}
                        disabled
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Link
                                to={() =>
                                  goToReferenceId(
                                    modalValues.referenceTable,
                                    modalValues.referenceId,
                                  )
                                }
                              >
                                <IconButton title="Ir para compra">
                                  <CallMadeIcon />
                                </IconButton>
                              </Link>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        value={modalValues.createdAt}
                        label="Realizada"
                        fullWidth
                        className={disabledTextField}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormInputDate
                        fullWidth
                        name="dueDate"
                        dateFormat="MM-DD-YYYY"
                        value={values.dueDate}
                        onChange={(value) =>
                          setFieldValue('dueDate', value, false)
                        }
                        clearable
                        label="Data de vencimento"
                      />
                    </Grid>
                    {modalValues.closedAt && (
                      <Grid item xs={12}>
                        <TextField
                          value={modalValues.closedAt}
                          label="Totalmente paga em"
                          fullWidth
                          className={disabledTextField}
                          disabled
                        />
                      </Grid>
                    )}
                  </Grid>
                </DialogContent>
                <DialogActions>
                  {!isEqualObject(values, modalValues) && (
                    <Button onClick={() => handleModalUpdate()} color="primary">
                      Cancelar
                    </Button>
                  )}
                  <Button
                    onClick={
                      isEqualObject(values, modalValues)
                        ? () => handleModalUpdate()
                        : () => submitForm()
                    }
                    color="primary"
                  >
                    {isEqualObject(values, modalValues) ? 'Fechar' : 'Salvar'}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
      )}
      <Dialog
        fullWidth
        open={modalPay}
        onExit={() => {
          setPayInfo();
          setPayment(initialPayment);
        }}
        onClose={() => setModalPay(false)}
      >
        <UIDialogTitle
          onClose={() => setModalPay(false)}
          id="alert-dialog-title"
        >
          {modalValues.name}
        </UIDialogTitle>
        <DialogContent>
          <Grid container direction="row" spacing={1}>
            {payInfo.data ? (
              <TableContainer>
                <Typography align="center" variant="h6" component="div">
                  Resumo do pagamento #{payInfo.data.data.payment.id}
                </Typography>
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell>Pagador</TableCell>
                      <TableCell>{credencials.name}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Valor da conta</TableCell>
                      <TableCell>
                        {formattedCurrency(payInfo.data.data.value)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Valor pago</TableCell>
                      <TableCell>
                        {formattedCurrency(payInfo.data.data.valuePaid)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Método de pagamento</TableCell>
                      <TableCell>
                        {
                          paymentInfo.data.find(
                            (value) =>
                              value.id ===
                              payInfo.data.data.payment.paymentMethod,
                          ).name
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Realizada em</TableCell>
                      <TableCell>{payInfo.data.data.payment.done}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Compra referente</TableCell>
                      <TableCell>
                        <Link
                          to={() =>
                            goToReferenceId(
                              modalValues.referenceTable,
                              payInfo.data.data.payment.referenceId,
                            )
                          }
                        >
                          Ver detalhes
                        </Link>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel shrink id="bills-to-pay__paymentMethod">
                      Método de pagamento
                    </InputLabel>
                    <Select
                      value={payment.paymentMethod}
                      onChange={(ev) =>
                        changeValuesPayment(ev.target.value, 'paymentMethod')
                      }
                      labelId="bills-to-pay__paymentMethod"
                      displayEmpty
                    >
                      {paymentInfo.data &&
                        paymentInfo.data.map((pay) => (
                          <MenuItem key={pay.id} value={pay.id}>
                            {pay.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <CurrencyTextField
                    type="tel"
                    value={payment.valuePaid}
                    error={Boolean(payment.errors)}
                    helperText={payment.errors}
                    label="Valor"
                    variant="standard"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    currencySymbol="R$"
                    outputFormat="string"
                    onChange={(ev, value) =>
                      changeValuesPayment(value, 'valuePaid')
                    }
                    fullWidth
                  />
                </Grid>
                {Number(payment.limitPay) < Number(payment.valuePaid) && (
                  <Typography
                    style={{
                      color: 'rgb(196, 53, 17)',
                      fontSize: 14,
                      marginTop: 10,
                    }}
                    paragraph
                  >
                    Atenção: O valor que está informando é maior que o restante
                    à ser pago na conta, assim fazendo com que a cobrança fique
                    maior. Verifique se o mesmo está correto antes de continuar
                  </Typography>
                )}
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          {payInfo.data ? (
            <Button
              onClick={() => setModalPay(false)}
              color="primary"
              autoFocus
            >
              Sair
            </Button>
          ) : (
            <>
              <Button onClick={() => setModalPay(false)} color="primary">
                Cancelar
              </Button>
              <Button
                onClick={
                  Number(payment.valuePaid) <= Number(payment.limitPay)
                    ? onPay
                    : () => setModalConfirmPayment(true)
                }
                color="primary"
                autoFocus
              >
                Pagar
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        open={modalTerminates}
        onExit={() => {
          setTerminatesInfo();
          setTextConfirm(initialTextConfirm);
        }}
        onClose={() => setModalTerminates(false)}
      >
        <UIDialogTitle
          onClose={() => setModalTerminates(false)}
          id="alert-dialog-title"
        >
          Atenção: valor total de pagamento ainda não foi atingido
        </UIDialogTitle>
        <DialogContent>
          <Grid container direction="row" spacing={1}>
            {terminatesInfo.data ? (
              <TableContainer>
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell>Valor da conta</TableCell>
                      <TableCell>
                        {formattedCurrency(terminatesInfo.data.bill.value)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Valor pago</TableCell>
                      <TableCell>
                        {formattedCurrency(terminatesInfo.data.bill.valuePaid)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Finalizado em</TableCell>
                      <TableCell>{terminatesInfo.data.bill.closedAt}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <>
                <Typography paragraph>
                  Encerrando esta conta agora, não será possível informar mais
                  pagamentos da mesma. Para continuar, digite{' '}
                  <strong>Encerrar conta</strong> no campo abaixo:
                </Typography>
                <TextField
                  value={textConfirm.closeAcount}
                  label="Digite o texto"
                  placeholder="Encerrar conta"
                  helperText={textConfirm.error}
                  error={!!textConfirm.error}
                  onChange={(ev) =>
                    setTextConfirm({ closeAcount: ev.target.value })
                  }
                  fullWidth
                />
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          {terminatesInfo.data ? (
            <Button
              onClick={() => setModalTerminates(false)}
              color="primary"
              autoFocus
            >
              Sair
            </Button>
          ) : (
            <>
              <Button onClick={() => setModalTerminates(false)} color="primary">
                Cancelar
              </Button>
              <Button
                onClick={onTerminates}
                disabled={textConfirm.closeAcount !== 'Encerrar conta'}
                color="primary"
                autoFocus
              >
                Ok, encerrar
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        open={modalConfirmPayment}
        onExit={() => {
          setTerminatesInfo();
          setTextConfirm(initialTextConfirm);
        }}
        onClose={() => setModalConfirmPayment(false)}
      >
        <UIDialogTitle
          onClose={() => setModalConfirmPayment(false)}
          id="alert-dialog-title"
        >
          Atenção
        </UIDialogTitle>
        <DialogContent>
          <Grid container direction="row" spacing={1}>
            <Typography paragraph>
              O valor informado é maior que o restante à ser pago na conta,
              assim fazendo com que a cobrança fique maior. Para continuar,
              digite <strong>Confirmar pagamento</strong> no campo abaixo:
            </Typography>
            <TextField
              value={textConfirm.confirmPayment}
              label="Digite o texto"
              placeholder="Confirmar pagamento"
              helperText={textConfirm.error}
              error={!!textConfirm.error}
              onChange={(ev) =>
                setTextConfirm({ confirmPayment: ev.target.value })
              }
              fullWidth
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModalConfirmPayment(false)} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={onPay}
            disabled={textConfirm.confirmPayment !== 'Confirmar pagamento'}
            color="primary"
            autoFocus
          >
            Ok, confirmar
          </Button>
        </DialogActions>
      </Dialog>
      <UIDialogConfirm
        open={alert}
        close={() => setAlert(false)}
        data={payInfo}
        messageSucess={payInfo.data && payInfo.data.message}
        messageError="Ops, ocorreu um erro ao pagar conta"
      />
      <UIDialogConfirm
        open={alertUpdate}
        close={() => setAlertUpdate(false)}
        data={updateInfo}
        setrequestinfo={setUpdateInfo}
        messageSucess="Conta atualizada com sucesso"
        messageError="Ops, ocorreu um erro ao pagar conta"
      />
      <UIDialogConfirm
        open={alertTerminates}
        close={() => setAlertTerminates(false)}
        data={setUpdateInfo}
        setrequestinfo={setUpdateInfo}
        messageSucess={terminatesInfo.data && terminatesInfo.data.message}
        messageError="Ops, ocorreu um erro ao pagar conta"
      />
    </>
  );
};

export default BillsToPay;
