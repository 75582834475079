import React from 'react';
import { Divider, Grid, Typography } from '@material-ui/core';

import { getHours } from 'date-fns';
import Carousel from 'react-material-ui-carousel';
import UIClock from '../UI/Clock/UIClock';
import UILogo from '../UI/Logo/UILogo';

import './homePage.css';
import useStorage from '../../utils/useStorage';
import StickyNotes from './Cards/stickyNotes';
import GoalCard from './Cards/goalCard';
import GoalTags from './Tags/goalTags';

const HomePage = () => {
  const [credencials] = useStorage('userAcess');
  function getTimeDay() {
    const currentHours = getHours(new Date());
    if (currentHours >= 0 && currentHours < 12) return 'Bom dia';
    if (currentHours >= 12 && currentHours < 18) return 'Boa tarde';
    return 'Boa noite';
  }
  return (
    <Grid container className="home-page">
      <Grid item xs={12} md={8}>
        <Grid container item xs className="home-page__info">
          <Typography variant="h5">
            {getTimeDay()},{' '}
            <span>{credencials.name} {credencials.lastName} </span> 👋
          </Typography>
          <UILogo size={40} />
          <UIClock />
        </Grid>
      </Grid>
      <Grid className="home-page__sliders" item xs={12} md={4}>
        <Carousel
          navButtonsAlwaysInvisible
          interval={100000}
          animation="slide"
          indicatorContainerProps={{ style: { marginTop: 0 } }}
          className="home-page__sliders-carousel"
        >
          <Grid container direction="column" key={0}>
            <Typography className="sticky-notes__title">Resumo</Typography>
            <Grid container item xs={12}>
              <GoalTags iconColor="#DDB217" />
            </Grid>
            <Divider style={{ margin: '15px 0px' }} />
            <Typography className="sticky-notes__title">
              Informações recentes
            </Typography>
            <Grid container key={1}>
              <GoalCard iconColor="#BDCCFF" />
            </Grid>
          </Grid>

          <Grid container direction="column" key={1}>
            <Typography className="sticky-notes__title">
              Metas, bora lá !!!
            </Typography>
            <Grid container item xs={12}>
              <GoalTags iconColor="#131765" />
            </Grid>
            <Divider style={{ margin: '15px 0px' }} />
            <Typography className="sticky-notes__title">
              Metas recentes
            </Typography>
            <GoalCard iconColor="#A9FF74" />
          </Grid>

          <Grid key={2}>
            <Typography className="sticky-notes__title">Lembretes</Typography>
            <StickyNotes />
          </Grid>
        </Carousel>
      </Grid>
    </Grid>
  );
};
export default HomePage;
