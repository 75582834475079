import React from 'react';
import StarsIcon from '@material-ui/icons/Stars';
import { Grid, Typography } from '@material-ui/core';
import './local.css';

const GoalTags = (children) => {
  const { iconColor } = children;
  return (
    <Grid container item xs={6} className="home-page__tags">
      <Grid item xs={12} md={3}>
        <StarsIcon style={{ fontSize: '30px', color: iconColor }} />
      </Grid>
      <Grid item xs={12} md={9}>
        <Typography paragraph>Titulo</Typography>
        <Typography className="home-page__goals">Meta</Typography>
      </Grid>
    </Grid>
  );
};

export default GoalTags;
