import { React, useState } from 'react';
import CountUp from 'react-countup';
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import Editable from 'react-editable-title';
import { emptyObject } from '../../../../utils/utils';
import useApi from '../../../../utils/useApi';
import './sessionCard.css';

const SessionCard = (children) => {
  const { data, handleModalSale, setOldValues, refreshCards } = children;
  const {
    session_id: sessionId,
    identifier,
    sale_metadata: saleMetadata,
    oldValue,
    id,
    responsible,
  } = data;
  const [modalExclude, setModelExclude] = useState(false);
  const [textTitle, setTextTitle] = useState(identifier);
  const [textTiny, setTextTiny] = useState(responsible);
  const [salesLoad] = useApi({
    method: 'put',
    url: '/sales/sessions',
  });

  const [sessionDelete] = useApi({
    method: 'delete',
    url: '/sales/sessions',
  });

  const deleteSession = () => {
    sessionDelete({
      data: emptyObject({ session_id: sessionId }),
      onCompleted: () => {
        refreshCards({});
      },
    });
  };

  const handleModalExclude = () => {
    setModelExclude(!modalExclude);
  };

  const handleTextTitleUpdate = (current) => {
    if (current !== textTitle) {
      setTextTitle(current);
      salesLoad({
        data: emptyObject({ session_id: sessionId, identifier: current }),
        onCompleted: () => {
          refreshCards({});
        },
      });
    }
  };

  const handleTextTinyUpdate = (current) => {
    if (current !== textTiny) {
      setTextTiny(current);
      salesLoad({
        data: emptyObject({ session_id: sessionId, responsible: current }),
        onCompleted: () => {
          refreshCards({});
        },
      });
    }
  };

  return (
    <div className="sessions-card__card">
      <Typography className="sessions-card__title">
        <Editable
          text={textTitle}
          placeholder="Type here"
          cb={handleTextTitleUpdate}
          className="sessions-card__title"
        />
      </Typography>
      <Typography className="sessions-card__tranfer">
        <Editable
          text={textTiny}
          placeholder="Type here"
          cb={handleTextTinyUpdate}
        />
      </Typography>
      <Typography
        noWrap
        className="sessions-card__money"
        onClick={() => handleModalSale(data)}
      >
        <span>
          <CountUp
            startOnMount
            decimals={2}
            decimal=","
            prefix="R$ "
            end={saleMetadata.total}
            start={oldValue}
            duration={2}
            onEnd={() => {
              if (Number(oldValue) !== Number(saleMetadata.total))
                setOldValues(id, saleMetadata.total);
            }}
          />
        </span>
      </Typography>
      <Typography
        className="sessions-card__tranfer"
        variant="overline"
        onClick={() => handleModalSale(data)}
      >
        Ver comanda...
      </Typography>
      <Button
        variant="contained"
        color="primary"
        size="small"
        startIcon={<DeleteIcon />}
        onClick={() => handleModalExclude()}
      />
      <Dialog
        open={modalExclude}
        onClose={handleModalExclude}
        aria-labelledby="draggable-dialog-title"
        fullWidth
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Cancelar Comanda
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Deseja encerrar e apagar essa comanda?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleModalExclude} color="primary">
            Voltar
          </Button>
          <Button onClick={deleteSession} color="secondary">
            Apagar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SessionCard;
