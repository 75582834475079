import React, { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Collapse,
  Fab,
  Grid,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import SearchIcon from '@material-ui/icons/Search';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import UIContainer from '../../UI/Container/UIContainer';
import useApi from '../../../utils/useApi';
import UIModalOldForm from '../../UI/ModalOldForm/UIModalOldForm';
import UIFormContainerSale from '../../UI/Form/ContainerSale/UIFormContainerSale';
import SaleSearchItem from '../Modal/searchItem';
import {
  emptyObject,
  formattedCurrency,
  isEqualObject,
} from '../../../utils/utils';
import SaleSelect from '../Select/saleSelect';
import UIDialogConfirm from '../../UI/Dialog/Confirm/UIDialogConfirm';
import SaleConfirm from '../Confirm/saleConfirm';
import './saleNew.css';
import useStyles from '../../../utils/useStyle';

const initialItem = {
  id: '',
  name: '',
  amount: 0,
  valueUn: 0,
  valueTotal: 0,
};

const initialOptions = {
  customer: null,
  plot: 1,
  promotion: null,
  totalGain: 0,
  discount: 0,
  total: 0,
  interest: 0,
  totalSum: 0,
  observation: '',
  amountPaid: 0,
  paymentMethod: 3,
};

const initialCalculate = {
  onlyCalculate: true,
  customer: null,
  promotion: null,
  products: {},
  services: {},
  interest: {},
  splitRemaining: 0,
};

const SaleNew = () => {
  const [open, setOpen] = useState(false);
  const { disabledTextField } = useStyles();

  // botôes para foco
  const btnInsert = document.querySelector('.sale-new__insert-button');
  const inputBarCode = document.getElementById('sale-new-search-item');

  // verificar se é mobile e constantes para versão mobile
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [collapse, setCollapse] = useState(false);
  const [collapseAdd, setCollapseAdd] = useState(true);
  const [scroll, setScroll] = useState(1);

  // valores para o container de salvar e opcoes
  const [cartValues, setCartValues] = useState(initialOptions);
  // array dos produtos na lista
  const [listItens, setListItens] = useState([]);
  // constante para guardar valores para calcular
  const [calculate, setCalculate] = useState(initialCalculate);

  // constante para armazenar input de codigo de barra
  const [barCode, setBarCode] = useState('');

  // consante para controlar o modal de confirmar venda
  const [modalConfirm, setModalConfirm] = useState(false);

  // modal search item
  const [modalSearch, setModalSearch] = useState(false);

  // select item no modal
  const [item, setItem] = useState(initialItem);

  // controle do alert para sucesso ou falha quando adiciona um item
  // e quando confirma a lista
  const [alertList, setAlertList] = useState(false);

  // ação para click esquerdo
  const [anchorEl, setAnchorEl] = useState(null);
  const [itemChose, setItemChose] = useState(null);

  const handleClickLeft = (event, data) => {
    setItemChose(data);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseClickLeft = () => {
    setItemChose(null);
    setAnchorEl(null);
  };

  const [salesLoad, salesInfo, setSaleInfo] = useApi({
    method: 'post',
    url: '/sales',
  });

  const [searchProductLoad] = useApi({
    debounceDelay: 500,
    method: 'get',
    url: '/products',
  });

  const [searchServicesLoad] = useApi({
    debounceDelay: 500,
    method: 'get',
    url: '/services',
  });

  function changeModal() {
    setOpen(!open);
  }

  function changeViewItens() {
    setCollapse(!collapse);
    setCollapseAdd(false);
  }

  function changeAddItens() {
    setCollapseAdd(!collapseAdd);
    setCollapse(false);
  }

  function changeSearchItens() {
    setModalSearch(!modalSearch);
  }

  function clearFormStorage() {
    localStorage.removeItem('formSaleOptions');
    localStorage.removeItem('formSaleItens');
    localStorage.removeItem('formCalculate');
  }

  function scrollTo(hash) {
    document.location.hash = `#${hash}`;
  }

  useEffect(() => {
    document.getElementById('sale-new-search-item').focus();
    const formSaleOptions = JSON.parse(localStorage.getItem('formSaleOptions'));
    const formSaleItens = JSON.parse(localStorage.getItem('formSaleItens'));
    const formCalculate = JSON.parse(localStorage.getItem('formCalculate'));
    if (
      !isEqualObject(formSaleItens || [], []) ||
      !isEqualObject(formSaleOptions || initialOptions, initialOptions)
    ) {
      setCartValues(formSaleOptions);
      setListItens(formSaleItens);
      setCalculate(formCalculate);
      changeModal();
    }
  }, []);

  function searchBarCode(value) {
    setBarCode(value);
    searchProductLoad({
      params: { barCode: value },
      onCompleted: (res) => {
        if (res) {
          const data = res.data[0];
          setItem({
            id: data.id,
            amount: 1,
            name: data.name,
            valueUn: data.value,
            valueTotal: data.value,
            typeItem: 'product',
          });
          document.querySelector('.sale-new__insert-button').focus();
        }
      },
    });
    searchServicesLoad({
      params: { barCode: value },
      onCompleted: (res) => {
        if (res) {
          const data = res.data[0];
          setItem({
            id: data.id,
            amount: 1,
            name: data.name,
            valueUn: data.value,
            valueTotal: data.value,
            typeItem: 'service',
          });
          btnInsert.focus();
        }
      },
    });
  }

  function checkCart(list = listItens) {
    const allProducts = list.filter((value) => value.typeItem === 'product');
    const allServices = list.filter((value) => value.typeItem === 'service');
    const products = allProducts.reduce((acc, { id, amount }) => {
      acc[id] = amount;
      return acc;
    }, {});
    const services = allServices.reduce((acc, { id, amount }) => {
      acc[id] = amount;
      return acc;
    }, {});

    const data = {
      ...calculate,
      products,
      services,
    };
    return emptyObject(data);
  }

  function selectItem(values) {
    setModalSearch(!modalSearch);
    setItem({
      id: values.id,
      amount: 1,
      name: values.name,
      valueUn: values.value,
      valueTotal: values.value,
      typeItem: values.typeItem,
      barCode: values.barCode,
    });
    setBarCode(values.barCode);
    btnInsert.focus();
  }

  function addItem(values) {
    if (item.name) {
      const testItens = [...listItens, values];
      let data = checkCart(testItens);

      const checkDuplicateItem = listItens.find((currentValue, index) => {
        if (currentValue.barCode === barCode) {
          listItens[index].amount += Number(values.amount);
        }
        return currentValue.barCode === barCode;
      });

      if (checkDuplicateItem) {
        data = checkCart(listItens);
        salesLoad({
          data,
          onCompleted: (res) => {
            if (res) {
              setListItens(listItens);
              setCartValues({ ...cartValues, ...res.data });
              setCalculate({ ...calculate, ...data });
              setItem(initialItem);
              inputBarCode.focus();
              if (mobile) {
                changeViewItens();
              }
            }
          },
        });
      } else {
        data = checkCart(testItens);
        salesLoad({
          data,
          onCompleted: (res) => {
            if (res) {
              setListItens(testItens);
              setCartValues({ ...cartValues, ...res.data });
              setCalculate({ ...calculate, ...data });
              setItem(initialItem);
              inputBarCode.focus();
              if (mobile) {
                changeViewItens();
              }
            }
          },
        });
      }
    }
    setBarCode('');
  }

  function editItem(index, value) {
    if (value >= 0) {
      const oldAmount = listItens[index].amount;
      const oldValueTotal = listItens[index].valueTotal;

      listItens[index].amount = value;
      listItens[index].valueTotal =
        Number(value) * Number(listItens[index].valueUn);

      const data = checkCart(listItens);

      salesLoad({
        data: emptyObject(data),
        onCompleted: (res) => {
          if (res) {
            setAlertList(false);
            setListItens(listItens);
            setCalculate({ ...calculate, ...data });
            setCartValues({ ...cartValues, ...res.data });
          } else {
            listItens[index].amount = oldAmount;
            listItens[index].valueTotal = oldValueTotal;
            setListItens(listItens);
            if (value) setAlertList(true);
          }
        },
      });
    }
  }

  function removeItem(index) {
    const itens = listItens.filter((data, indexData) => indexData !== index);

    const data = checkCart(itens);

    if (itens.length <= 0) {
      setListItens([]);
      setCartValues(initialOptions);
      setCalculate(initialCalculate);
      setAlertList(false);
    } else {
      salesLoad({
        data,
        onCompleted: (res) => {
          if (res) {
            setListItens(itens);
            setCalculate({ ...calculate, ...data });
            setCartValues({ ...cartValues, ...res.data });
          }
        },
      });
    }

    if (mobile) {
      handleCloseClickLeft();
    }
  }

  function selectClient(value) {
    setCartValues({ ...cartValues, customer: value || null });
    setCalculate({ ...calculate, customer: value.id || null });
  }

  function selectPlots(value) {
    if (value >= 0 && value <= 72) {
      cartValues.plot = value;
      calculate.plots = value;
      salesLoad({
        data: emptyObject(calculate),
        onCompleted: (res) => {
          if (res) {
            setCartValues({ ...cartValues, ...res.data });
            setCalculate({ ...calculate, plots: Number(value) || null });
          }
        },
      });
    }
  }

  function selectPromotion(value) {
    cartValues.promotion = value;
    calculate.promotion = value && value.id;
    salesLoad({
      data: emptyObject(calculate),
      onCompleted: (res) => {
        if (res) {
          setCartValues({ ...cartValues, ...res.data });
          setCalculate({ ...calculate, promotion: value && value.id });
        } else {
          calculate.promotion = null;
          setAlertList(true);
          setCartValues({ ...cartValues, promotion: null });
        }
      },
    });
  }

  function selectInterest(value) {
    if (value >= 0 && value <= 100) {
      cartValues.interest = value;

      const objInterest = {
        percentage: value || 0,
        type: 'simples',
      };

      calculate.interest = objInterest;

      salesLoad({
        data: emptyObject(calculate),
        onCompleted: (res) => {
          if (res) {
            setAlertList(false);
            setCartValues({ ...cartValues, ...res.data });
            setCalculate({ ...calculate, interest: objInterest });
          }
        },
      });
    }
  }

  function confirmSale() {
    salesLoad({
      data: emptyObject(calculate),
      onCompleted: (res) => {
        if (res) {
          setCartValues({ ...cartValues, ...res.data });
          setModalConfirm(true);
        } else {
          setAlertList(true);
        }
      },
    });
  }

  // reseta o form após concluir venda
  function resetform() {
    setCartValues(initialOptions);
    setCalculate(initialCalculate);
    setListItens([]);
    clearFormStorage();
    setModalConfirm(!modalConfirm);
  }

  // limpa o form antigo do local storage
  function clearForm() {
    setCartValues(initialOptions);
    setListItens([]);
    setCalculate(initialCalculate);
    clearFormStorage();
    setOpen(!open);
  }

  function nextStateMobile() {
    const select = document.getElementById('sale-new-select');
    const confirm = document.getElementById('sale-new-confirm');
    const back = document.querySelector('.sale-new-return');
    if (scroll === 1) {
      select.style.visibility = 'visible';
      select.style.opacity = 1;
      scrollTo('sale-new-select');
    }
    if (scroll === 2) {
      confirm.style.visibility = 'visible';
      confirm.style.opacity = 1;
      back.style.bottom = '10px';
      scrollTo('sale-new-confirm');
    }
    setScroll(scroll + 1);
  }

  function returnStateMobile() {
    const select = document.getElementById('sale-new-select');
    const confirm = document.getElementById('sale-new-confirm');
    const back = document.querySelector('.sale-new-return');
    if (scroll === 2) {
      select.style.visibility = 'hidden';
      select.style.opacity = 0;
      scrollTo('sale-new-search');
    }
    if (scroll === 3) {
      confirm.style.visibility = 'hidden';
      confirm.style.opacity = 0;
      back.style.bottom = '60px';
      scrollTo('sale-new-select');
    }
    setScroll(scroll - 1);
  }

  if (
    !isEqualObject(cartValues, initialOptions) ||
    !isEqualObject(listItens, [])
  ) {
    localStorage.setItem('formSaleItens', JSON.stringify(listItens));
    localStorage.setItem('formSaleOptions', JSON.stringify(cartValues));
    localStorage.setItem('formCalculate', JSON.stringify(calculate));
  }

  return (
    <Grid
      container
      id="sale-new-search"
      style={{ height: mobile ? 'auto' : '100%', scrollBehavior: 'smooth' }}
    >
      <Formik enableReinitialize initialValues={item} onSubmit={addItem}>
        {({ errors, touched, values, setFieldValue }) => (
          <Form style={{ width: '100%' }}>
            <UIContainer container style={{ minHeight: 'auto' }}>
              {mobile && (
                <ListItem button onClick={changeAddItens}>
                  <ListItemIcon>
                    <AddIcon />
                  </ListItemIcon>
                  <ListItemText primary="Adicionar itens" />
                  {collapseAdd ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
              )}
              <Collapse
                in={mobile ? collapseAdd : true}
                timeout="auto"
                unmountOnExit
                style={{ width: '100%' }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} md={3}>
                    <TextField
                      id="sale-new-search-item"
                      value={barCode}
                      autoComplete="off"
                      label="Código barras (Shift + A)"
                      onChange={(ev) => searchBarCode(ev.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              title="Atalho: Shift + Espaço"
                              onClick={changeSearchItens}
                            >
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <Field
                      name="name"
                      label="Item"
                      placeholder="Nome do item"
                      fullWidth
                      className={disabledTextField}
                      disabled
                      as={TextField}
                    />
                  </Grid>
                  <Grid item xs={12} md={1}>
                    <TextField
                      value={values.amount}
                      onChange={(ev) => {
                        if (ev.target.value >= 0) {
                          setFieldValue('amount', ev.target.value);
                          setFieldValue(
                            'valueTotal',
                            values.valueUn * Number(ev.target.value),
                          );
                        }
                      }}
                      label="Qntd."
                      type="number"
                      error={Boolean(errors.amount)}
                      helperText={touched.amount && errors.amount}
                      placeholder="Insira a quantidade"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <CurrencyTextField
                      type="tel"
                      value={values.valueUn}
                      label="Valor unitario"
                      decimalCharacter=","
                      digitGroupSeparator="."
                      currencySymbol="R$"
                      outputFormat="string"
                      fullWidth
                      className={disabledTextField}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <CurrencyTextField
                      type="tel"
                      value={values.valueTotal}
                      label="Valor total"
                      decimalCharacter=","
                      digitGroupSeparator="."
                      currencySymbol="R$"
                      outputFormat="string"
                      fullWidth
                      className={disabledTextField}
                      disabled
                    />
                  </Grid>
                  <Grid
                    container
                    item
                    justify={mobile ? 'flex-end' : 'center'}
                    alignItems={mobile ? 'flex-end' : 'center'}
                    xs={12}
                    md={1}
                  >
                    <Button
                      variant="contained"
                      type="submit"
                      className="sale-new__insert-button"
                      startIcon={
                        (searchProductLoad.loading && (
                          <CircularProgress size="0.9rem" color="inherit" />
                        )) ||
                        (searchServicesLoad.loading && (
                          <CircularProgress size="0.9rem" color="inherit" />
                        ))
                      }
                    >
                      Inserir
                    </Button>
                  </Grid>
                </Grid>
              </Collapse>
            </UIContainer>
          </Form>
        )}
      </Formik>

      <SaleSearchItem
        open={modalSearch}
        mobile={mobile}
        handleModal={changeSearchItens}
        selectItem={selectItem}
        btnInsert={btnInsert}
      />

      <Grid
        item
        container
        justify="space-between"
        xs={12}
        style={{
          height: mobile ? 'auto' : '65%',
          paddingBottom: '15px',
        }}
      >
        <UIContainer
          container
          item
          style={{
            width: mobile ? '100%' : '70%',
            height: mobile ? 'auto' : '100%',
            overflow: 'auto',
          }}
        >
          {!mobile ? (
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Qntd.</TableCell>
                    <TableCell>Produto</TableCell>
                    <TableCell>Valor un</TableCell>
                    <TableCell>Valor total</TableCell>
                    <TableCell>Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listItens !== [] &&
                    listItens &&
                    listItens.map((data, index) => (
                      <TableRow key={data.id} hover>
                        <TableCell
                          style={{ maxWidth: 100 }}
                          component="th"
                          scope="row"
                        >
                          <TextField
                            value={data.amount}
                            onChange={(ev) => editItem(index, ev.target.value)}
                            onBlur={(ev) => {
                              if (!ev.target.value || ev.target.value === '0') {
                                editItem(index, 1);
                              }
                            }}
                            type="number"
                          />
                        </TableCell>
                        <TableCell>{data.name}</TableCell>
                        <TableCell>{formattedCurrency(data.valueUn)}</TableCell>
                        <TableCell>
                          {formattedCurrency(data.valueTotal)}
                        </TableCell>
                        <TableCell>
                          <span title="Excluir item">
                            <IconButton onClick={() => removeItem(index)}>
                              <DeleteIcon />
                            </IconButton>
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <>
              <ListItem button onClick={changeViewItens}>
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                <ListItemText primary="Ver itens" />
                {collapse ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse
                in={collapse}
                timeout="auto"
                unmountOnExit
                style={{ overflow: 'auto', width: '100%' }}
              >
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Qntd.</TableCell>
                        <TableCell>Produto</TableCell>
                        <TableCell>Valor un</TableCell>
                        <TableCell>Valor total</TableCell>
                        <TableCell>Ações</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {listItens !== [] &&
                        listItens &&
                        listItens.map((data, index) => (
                          <TableRow key={data.id} hover>
                            <TableCell component="th" scope="row">
                              <TextField
                                value={data.amount}
                                onChange={(ev) =>
                                  editItem(index, ev.target.value)
                                }
                                type="number"
                              />
                            </TableCell>
                            <TableCell
                              onClick={(ev) => handleClickLeft(ev, index)}
                            >
                              {data.name}
                            </TableCell>
                            <TableCell
                              onClick={(ev) => handleClickLeft(ev, index)}
                            >
                              {formattedCurrency(data.valueUn)}
                            </TableCell>
                            <TableCell
                              onClick={(ev) => handleClickLeft(ev, index)}
                            >
                              {formattedCurrency(data.valueTotal)}
                            </TableCell>
                            <TableCell>
                              <span title="Excluir item">
                                <IconButton onClick={() => removeItem(index)}>
                                  <DeleteIcon />
                                </IconButton>
                              </span>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Collapse>
            </>
          )}
        </UIContainer>
        <UIContainer
          id="sale-new-select"
          container
          item
          style={{
            width: mobile ? '100%' : '29%',
            height: mobile ? 'auto' : '100%',
            overflow: 'auto',
          }}
        >
          <SaleSelect
            options={cartValues}
            setOptions={setCartValues}
            selectPlots={selectPlots}
            selectClient={selectClient}
            selectPromotion={selectPromotion}
            selectInterest={selectInterest}
            changeSearchItens={changeSearchItens}
            confirmSale={confirmSale}
            inputBarCode={inputBarCode}
          />
        </UIContainer>
      </Grid>

      <UIFormContainerSale
        id="sale-new-confirm"
        mobile={mobile}
        style={{ minHeight: mobile ? 'auto' : '20%' }}
        onConfirmed={confirmSale}
        sale={salesInfo}
        data={cartValues}
      />

      <SaleConfirm
        open={modalConfirm}
        options={cartValues}
        calculate={calculate}
        setOptions={setCartValues}
        handleModal={() => setModalConfirm(!modalConfirm)}
        resetform={resetform}
      />

      <UIDialogConfirm
        open={alertList}
        data={salesInfo}
        timeSucess={3000}
        messageSucess="Ação feita com sucesso!"
        close={() => setAlertList(!alertList)}
        setrequestinfo={setSaleInfo}
      />

      <UIModalOldForm
        open={open}
        changeModal={changeModal}
        setFormValues={clearForm}
        msgAlert="Há dados no carrinho, deseja continuar a venda?"
        msgContinue="Continuar venda"
        msgNew="Nova venda"
      />

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseClickLeft}
      >
        <MenuItem onClick={() => removeItem(itemChose)}>
          Exluir produto
        </MenuItem>
      </Menu>

      {mobile && scroll !== 3 && (
        <Tooltip className="sale-new-next" title="Avançar">
          <Fab
            style={{ backgroundColor: '#178208', color: '#fff' }}
            size="small"
            variant="extended"
            onClick={nextStateMobile}
          >
            <ArrowDownwardIcon />
            Avançar
          </Fab>
        </Tooltip>
      )}
      {mobile && scroll > 1 && scroll < 4 && (
        <Tooltip className="sale-new-return" title="Voltar">
          <Fab
            style={{ backgroundColor: '#C40409', color: '#fff' }}
            size="small"
            variant="extended"
            onClick={returnStateMobile}
          >
            <ArrowUpwardIcon />
            Voltar
          </Fab>
        </Tooltip>
      )}
    </Grid>
  );
};

export default SaleNew;
