import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import './local.css';

const StickyNotes = () => (
  <Grid item xs={12} className="sticky-notes__card">
    <Grid item xs={12}>
      <Typography className="home-page__card-title" style={{ float: 'left' }}>
        O seu lembrete ficará assim
      </Typography>
      <Typography className="home-page__card-x" onClick={undefined}>
        x
      </Typography>
    </Grid>
    <Typography className="home-page__card-body">
      Exemplo de lembrete
    </Typography>
    <Typography variant="button" className="home-page__card-goTo">
      Ir para
    </Typography>
  </Grid>
);

export default StickyNotes;
