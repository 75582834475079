import React, { useState, useContext } from 'react';
import { Grid, IconButton } from '@material-ui/core';
import { Storefront } from '@material-ui/icons';
import { Link, useHistory } from 'react-router-dom';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import LoyaltyIcon from '@material-ui/icons/Loyalty'; // Promocoes
import PeopleIcon from '@material-ui/icons/People';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import StorageIcon from '@material-ui/icons/Storage';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import BuildIcon from '@material-ui/icons/Build';

import useStorage from '../../../utils/useStorage';
import MainSlideItens from './Itens/slideItens';
import UILogo from '../../UI/Logo/UILogo';
import StoreContext from '../../Store/context';
import MainSlidePerfil from './Perfil/slidePerfil';

import './slide.css';
import ErrorNoAutentication from '../../Error/NoAutentication/ErrorNoAutentication';

const MainSlide = () => {
  const [open, setOpen] = useState(false);
  const { credencials, setCredencials } = useContext(StoreContext);
  const [, , remove] = useStorage('credencials');
  const history = useHistory();

  function removerUser() {
    remove();
    setCredencials(null);
    history.push('/');
  }

  function toggleSlide() {
    setOpen(!open);
  }

  const changeContent = (route) => {
    if (history.location.pathname !== route) {
      history.push(route);
    }
  };

  return (
    <>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="flex-start"
        id="main-base-slide"
        className="main-base-slide__slide"
      >
        <Grid
          container
          direction="row"
          justify={open ? 'space-between' : 'center'}
          alignItems="center"
          className="main-base-slide__toggle-slide"
        >
          {open && (
            <Link to="/home">
              <UILogo size="25px" />
            </Link>
          )}
          <IconButton onClick={toggleSlide}>
            <label htmlFor="main-base__slide-itens">
              {open ? (
                <MenuOpenIcon fontSize="large" />
              ) : (
                <MenuIcon fontSize="large" />
              )}
            </label>
          </IconButton>
        </Grid>

        <MainSlidePerfil
          open={open}
          function="Operador de caixa"
          credencials={credencials}
          exit={removerUser}
          icon={<AccountCircleIcon />}
          options={[
            { name: 'Editar', action: null },
            { name: 'Sair', action: removerUser, exit: true },
          ]}
        />

        <Grid item className="main-base-slide__divider" />

        <MainSlideItens
          open={open}
          name="Caixa"
          icon={<Storefront />}
          options={[
            {
              name: 'Novo',
              action: () => changeContent('/home/caixas/novo'),
            },
            {
              name: 'Pesquisar/ editar',
              action: () => changeContent('/home/caixas/pesquisar'),
            },
            {
              name: 'Abrir/ fechar',
              action: () => changeContent('/home/caixas/detalhes'),
            },
            {
              name: 'Transferência',
              action: () => changeContent('/home/caixas/transferencia'),
            },
          ]}
        />
        <MainSlideItens
          open={open}
          name="Clientes"
          icon={<AssignmentIndIcon />}
          options={[
            {
              name: 'Novo',
              action: () => changeContent('/home/clientes/novo'),
            },
            {
              name: 'Pesquisar/ editar',
              action: () => changeContent('/home/clientes/pesquisar'),
            },
          ]}
        />
        <MainSlideItens
          open={open}
          name="Vendas"
          icon={<AddShoppingCartIcon />}
          options={[
            {
              name: 'Nova',
              action: () => changeContent('/home/vendas/nova'),
            },
            {
              name: 'Pesquisar/ editar',
              action: () => changeContent('/home/vendas/pesquisar'),
            },
            {
              name: 'Pagamentos',
              action: () => changeContent('/home/vendas/pagamentos'),
            },
            {
              name: 'Comandas',
              action: () => changeContent('/home/vendas/comanda'),
            },
          ]}
        />
        <MainSlideItens
          open={open}
          name="Produtos"
          icon={<StorageIcon />}
          options={[
            { name: 'Novo', action: () => changeContent('/home/produto/novo') },
            {
              name: 'Pesquisar/ editar',
              action: () => changeContent('/home/produto/pesquisar'),
            },
            {
              name: 'Categorias',
              action: () => changeContent('/home/categoria/nova/p'),
            },
          ]}
        />
        <MainSlideItens
          open={open}
          name="Serviços"
          icon={<BuildIcon />}
          options={[
            { name: 'Novo', action: () => changeContent('/home/servico/novo') },
            {
              name: 'Pesquisar/ editar',
              action: () => changeContent('/home/servico/pesquisar'),
            },
            {
              name: 'Categorias',
              action: () => changeContent('/home/categoria/nova/s'),
            },
          ]}
        />
        <MainSlideItens
          open={open}
          name="Usuários"
          icon={<PeopleIcon />}
          options={[
            {
              name: 'Novo',
              action: () => changeContent('/home/usuarios/novo'),
            },
            {
              name: 'Pesquisar/ editar',
              action: () => changeContent('/home/usuarios/pesquisar'),
            },
          ]}
        />
        <MainSlideItens
          open={open}
          name="Promoções"
          icon={<LoyaltyIcon />}
          options={[
            {
              name: 'Nova',
              action: () => changeContent('/home/promocao/nova'),
            },
            {
              name: 'Pesquisar/ editar',
              action: () => changeContent('/home/promocao/pesquisar'),
            },
          ]}
        />
        <MainSlideItens
          open={open}
          name="Gestão"
          icon={<TrendingUpIcon />}
          options={[
            {
              name: 'Contas a pagar',
              action: () => changeContent('/home/gestao/contas'),
            },
            {
              name: 'Contas a receber',
              action: () => changeContent('/home/vendas/pesquisar'),
            },
            {
              name: 'Custos',
              action: () => changeContent('/home/gestao/custos'),
            },
            {
              name: 'Estoque',
              action: () => changeContent('/home/gestao/estoque'),
            },
            {
              name: 'Livros de Caixa',
              action: () => changeContent('/home/caixas/fechamentos'),
            },
            

            /* { name: 'Visão Geral', action: null },
            { name: 'Relatórios', action: null },
            { name: 'Operadores', action: null },
            { name: 'Clientes', action: null },
            { name: 'Vendas', action: null }, */
          ]}
        />

        {open && (
          <>
            <Grid item className="main-base-slide__divider" />
            <MainSlideItens
              open={open}
              exit={removerUser}
              name="Sair"
              icon={<ExitToAppIcon />}
            />
          </>
        )}
      </Grid>
      <ErrorNoAutentication />
    </>
  );
};

export default MainSlide;
